	/*
  	Flaticon icon font: Flaticon
  	Creation date: 26/10/2020 11:08
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
       
font-style: normal;
margin-left: 20px;
}

.flaticon-barbell:before { content: "\f100"; }
.flaticon-treadmill:before { content: "\f101"; }
.flaticon-six-pack:before { content: "\f102"; }
.flaticon-bodybuilding:before { content: "\f103"; }
.flaticon-medal:before { content: "\f104"; }
.flaticon-muscular-bodybuilder-with-clock:before { content: "\f105"; }
.flaticon-trends:before { content: "\f106"; }
.flaticon-weightlifting:before { content: "\f107"; }
.flaticon-training:before { content: "\f108"; }
.flaticon-support:before { content: "\f109"; }